<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Etalon</h1>
            </div>
            <div class="default_box_content bg_white p15">

            </div>
        </div>       
    </div>
</template>
<script>
export default {
    name: "Etalon",
    components: {},
    metaInfo() {
        return {
            title: `Редакция `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Реклама  - ${this.$myCity.name}`},
                {property: 'og:description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },

}
</script>
<style scoped>
</style>